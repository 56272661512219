/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Image, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Title, Text } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"fotogalerie"}>
        <Column className="--menu pb--08 pt--08" style={{"backgroundColor":"rgba(255,255,255,1)"}} menu={true}>
          
          <Menu className="--right" style={{"maxWidth":1310}} fullscreen={false}>
            
            <ColumnWrapper className="menu-logo-box --center" style={{"maxWidth":310}}>
              
              <Image style={{"maxWidth":142}} alt={""} src={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png"} svg={false} href={"/"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=350x_.png 350w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=660x_.png 660w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=860x_.png 860w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=1400x_.png 1400w, https://cdn.swbpg.com/t/13078/048b88fe8f984ba5aab1738fe1364461_s=2000x_.png 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox className="--style4 --right">
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kurzy"} target={""} content={"Kurzy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/zajezdy"} target={""} content={"Zájezdy"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/obchod"} target={""} content={"Obchod"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/sluzby"} target={""} content={"Služby"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/bazen"} target={""} content={"Bazén"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/klub"} target={""} content={"Klub"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/o-nas"} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/fotogalerie"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--cbtn2 btn-box--sbtn2 btn-box--center ff--3 fs--16 w--600" href={"/kontakt"} target={""} content={"Kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"qutqxxt44rp"} style={{"marginTop":0,"paddingTop":28,"paddingBottom":6.125}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center ff--3" style={{"paddingBottom":0}} content={"<span style=\"color: rgb(208, 2, 27);\">Fotogalerie</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"6x3wnavvph7"} style={{"paddingTop":28,"paddingBottom":23.9375}}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/cd3cce72068841a19142314699c22c64_s=660x_.jpg"} use={"page"} href={"/fotoshagra"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/cd3cce72068841a19142314699c22c64_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/cd3cce72068841a19142314699c22c64_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/cd3cce72068841a19142314699c22c64_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Marsa Shagra"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ce1cf97579a14a64be31bec02d3e1a4f_s=660x_.jpg"} use={"page"} href={"/fotoelba"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/ce1cf97579a14a64be31bec02d3e1a4f_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ce1cf97579a14a64be31bec02d3e1a4f_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ce1cf97579a14a64be31bec02d3e1a4f_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Elba"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ab45b91e286e4fb982b39dcdab6424dc_s=660x_.jpg"} use={"page"} href={"/fotomale"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/ab45b91e286e4fb982b39dcdab6424dc_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ab45b91e286e4fb982b39dcdab6424dc_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/ab45b91e286e4fb982b39dcdab6424dc_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Maledivy"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/81518e7079e242969ea0206c801e537e_s=860x_.jpg"} use={"page"} href={"/fotopag"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/81518e7079e242969ea0206c801e537e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/81518e7079e242969ea0206c801e537e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/81518e7079e242969ea0206c801e537e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/13078/81518e7079e242969ea0206c801e537e_s=1400x_.jpg 1400w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Ostrov Pag"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=660x_.jpg"} use={"page"} href={"/fotomexiko"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/18d72a48eb664e17bef9c4522c705b7b_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Mexiko"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/1009a7500848410ebba87e18f647d972_s=660x_.jpg"} use={"page"} href={"/fotoegyptsafari"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/1009a7500848410ebba87e18f647d972_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/1009a7500848410ebba87e18f647d972_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/1009a7500848410ebba87e18f647d972_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Egypt - safari"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/950681ac3df74604a6bfb8ccdf7b2457_s=350x_.jpg"} use={"page"} href={"/pagkustici"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/950681ac3df74604a6bfb8ccdf7b2457_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Francie - vraky"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/2d534fed6c1f4bbaab6d4dac7924fae1_s=350x_.jpg"} use={"page"} href={"/fotoestartit"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/2d534fed6c1f4bbaab6d4dac7924fae1_s=350x_.jpg 350w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Estartit"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/ee4e510984994e2697c07029241a2504_s=660x_.jpg"} use={"page"} href={"/fotoklub"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/ee4e510984994e2697c07029241a2504_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/ee4e510984994e2697c07029241a2504_s=660x_.jpg 660w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Klubové akce"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape4" src={"https://cdn.swbpg.com/t/13078/bb3dc4b083fd47afb03fdb58aeed2b6c_s=660x_.jpg"} use={"page"} href={"/fotobazen"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655,"marginBottom":0,"paddingBottom":0}} srcSet={"https://cdn.swbpg.com/t/13078/bb3dc4b083fd47afb03fdb58aeed2b6c_s=350x_.jpg 350w, https://cdn.swbpg.com/t/13078/bb3dc4b083fd47afb03fdb58aeed2b6c_s=660x_.jpg 660w, https://cdn.swbpg.com/t/13078/bb3dc4b083fd47afb03fdb58aeed2b6c_s=860x_.jpg 860w"} position={null}>
              </Image>

              <Title className="title-box title-box--center ff--3" style={{"marginTop":16.7578125,"paddingTop":0}} content={"Kurzy / bazény"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1b4itn5" style={{"marginTop":17,"paddingTop":30,"paddingBottom":7.6171875}} layout={"l1"} css={css`
      
    background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(255,255,255,0.65), rgba(255,255,255,0.65)), url(https://cdn.swbpg.com/t/13078/9a254d9c36af49c3bbd19ff443ad56fe_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 title-box--invert mt--0" content={"<span style=\"color: rgb(0, 0, 0);\">Orca diving, s.r.o.</span>"}>
              </Title>

              <Text className="text-box ff--3 text-box--invert" style={{"marginBottom":0,"paddingBottom":0}} content={"<span style=\"color: rgb(0, 0, 0);\">Ledařská 433/9, Praha 4, 147 00, Česká Republika</span>"}>
              </Text>

              <Image style={{"maxWidth":236,"marginTop":16.6875,"paddingTop":0}} alt={""} src={"https://cdn.swbpg.com/o/13078/000a415e28ce44c5972c4f22d2c183db.PNG"} svg={false} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={""} content={null}>
              </Image>

              <Image style={{"maxWidth":36,"marginTop":0,"paddingTop":10,"paddingBottom":0}} alt={""} src={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png"} svg={false} url={"https://www.facebook.com/orcadivingpotapeni"} href={"https://www.facebook.com/orcadivingpotapeni"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/13078/7555fba4134a4a698b9f5797f5549d68_e=63x61x388x388_s=350x_.png 350w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}